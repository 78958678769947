.lpg .header .langue{
  display: flex;
  flex-direction: column;
  a{
    display: flex;
    color:var(--color-gris);
    font-weight: 500;
    font-size: 1.4rem;
    text-decoration: none;
    &.is-active{
      color:var(--color-bleu);
    }
  }
  &__line{
    background-color: var(--color-rouge);
    width: 19px;
    height:1px;
    margin: 5px 0;
  }
}
