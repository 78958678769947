.lpg .radio-choix{
  margin: 25px 0;
  padding: 0 0 0 120px;

  &__container{
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }

  &__item{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    width: 170px;
    height:123px;
    border: 1px solid var(--color-bleu);
    margin-right: -1px;
    cursor: pointer;
    transition: .4s;
    &__image{
      display: block;
      width: 80px;
      height:75px;
      background-position: center;
      background-repeat: no-repeat;
      &.choix1{
        background-image: url('../img/organisation/choix1.svg');
        background-size: 70px;
      }
      &.choix2{
        background-image: url('../img/organisation/choix2.svg');
        background-size: 70px;
      }
      &.choix3{
        background-image: url('../img/organisation/choix3.svg');
        background-size: 70px;
      }
    }

    &__libelle{
      font-family: var(--font-basic);
      font-size: 1.3rem;
      color: var(--color-bleu);
    }

  }

  &__item.is-active{
    &::after{
      content:'';
      width: calc(100% + 3px);
      height: calc(100% + 3px);
      position: absolute;
      left:-2px;
      top:-2px;
      border:2px solid var(--color-jaune);
      z-index: 1;
    }
    &:first-child{
      &::after{
        width: calc(100% + 2px);
        border-right: 0;
      }
    }

    &:last-child{
      &::after{
        width: calc(100% + 2px);
        border-left: 0;
        left: -1px;
      }
    }
    .radio-choix__item__image{
      &.choix1{
        background-image: url('../img/organisation/choix1-active.svg');
      }
      &.choix2{
        background-image: url('../img/organisation/choix2-active.svg');
      }
      &.choix3{
        background-image: url('../img/organisation/choix3-active.svg');
      }
    }
    .radio-choix__item__libelle{
      color: var(--color-jaune);
    }
  }

  &__nbResponses{
    display: block;
    width: 100%;
    max-width: 508px;
    font-family: var(--font-basic);
    font-size: 1.3rem;
    color: var(--color-gris);
    margin: 0 auto;
  }

  form{
    input{
      display:none;
    }
  }

  .bloc-cta{
		margin-top: 30px;
		text-align: right;
	}
}

@media screen and (max-width: 1199px) {
  html .lpg .radio-choix.radio-choix--6choix,.lpg .radio-choix.radio-choix--3choix{
    padding: 0 20px 0;
    .radio-choix__container{
      flex-wrap: wrap;
      margin: 8px auto 0;
    }
    .radio-choix__item{
      margin: 0 5px 10px;
      &:last-child{
        &::after{
          border-left: 2px solid var(--color-jaune);
          width: calc(100% + 3px);
        }
      }
      &:first-child{
        &::after{
          border-right: 2px solid var(--color-jaune);
          width: calc(100% + 3px);
        }
      }
    }
    .bloc-cta{
  		text-align: center;
  	}
  }
}


@media screen and (max-width: 991px) {
  html .lpg .radio-choix.radio-choix--6choix,.lpg .radio-choix.radio-choix--3choix{
    .radio-choix__container{
      max-width: 540px;
    }
  }
}

@media screen and (max-width: 445px) {
  html .lpg .radio-choix.radio-choix--6choix,.lpg .radio-choix.radio-choix--3choix{
    .radio-choix__item{
      width: calc(50% - 10px);
    }
  }
}
