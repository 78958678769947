.lpg .pop-up{
  display: none;
  position: fixed;
  left:0;
  top:0;
  width: 100%;
  height:100%;
  z-index: 900;
  background-color: rgba(245,245,245,.7);

  &.is-active{
    display: block;
  }

  &__item{
    position: absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    width: 100%;
    max-width: 610px;
    padding: 35px 55px 40px;
    background-color: #FFF;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0px 3px 23px 0px rgba(54,14,17,.16);

    &__smiley{
      display: block;
      width: 41px;
      height:43px;
      margin: 0 auto 20px;
    }

    p{
      display: block;
      color:var(--color-bleu);
      font-family: var(--font-basic);
      font-size: var(--font-size-1);
      margin: 24px 0;
      span{
        color:var(--color-rouge);
      }
    }

    .bloc-cta{
      margin: 35px 0 0;
      text-align: center;
    }
  }

}
