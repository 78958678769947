.lpg .radio-choix.radio-choix--6choix{

  .radio-choix__container{
    max-width: 508px;
    flex-wrap: wrap;
    margin: 8px auto 0;
  }

  .radio-choix__item{
    margin-bottom: -1px;
    &__image{
      width: 60px;
      height:55px;
      margin-bottom: 10px;
      &.choix1{
        background-image: url('../img/interet/choix1.svg');
        background-size: 38px;
      }
      &.choix2{
        background-image: url('../img/interet/choix2.svg');
        background-size: 50px;
      }
      &.choix3{
        background-image: url('../img/interet/choix3.svg');
        background-size: 30px;
      }
      &.choix4{
        background-image: url('../img/interet/choix4.svg');
        background-size: 32px;
      }
      &.choix5{
        background-image: url('../img/interet/choix5.svg');
        background-size: 45px;
      }
      &.choix6{
        background-image: url('../img/interet/choix6.svg');
        background-size: 38px;
      }
    }

    &:first-child{
      border-right: 1px solid var(--color-bleu);
    }

    &:last-child{
      border-left: 1px solid var(--color-bleu);
    }
  }

  .radio-choix__item.is-active{

    &::after{
      z-index: 1;
      width: calc(100% + 3px);
    }

    &:first-child{
      &::after{
        border-right: 2px solid var(--color-jaune);
      }
    }

    &:nth-child(4){
      &::after{
        height: calc(100% + 3px);
        top:-2px;
      }
    }

    &:nth-child(5){
      &::after{
        height: calc(100% + 3px);
        top:-2px;
      }
    }

    &:last-child{
      &::after{
        border-left: 2px solid var(--color-jaune);
        height: calc(100% + 3px);
        left: -2px;
        top:-2px;
      }
    }

    .radio-choix__item__image{
      &.choix1{
        background-image: url('../img/interet/choix1-active.svg');
      }
      &.choix2{
        background-image: url('../img/interet/choix2-active.svg');
      }
      &.choix3{
        background-image: url('../img/interet/choix3-active.svg');
      }
      &.choix4{
        background-image: url('../img/interet/choix4-active.svg');
      }
      &.choix5{
        background-image: url('../img/interet/choix5-active.svg');
      }
      &.choix6{
        background-image: url('../img/interet/choix6-active.svg');
      }
    }
  }

}
