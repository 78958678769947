/* ====================
   Typography
   ==================== */
h1, .h1 {

}

h2, .h2 {

}

h3, .h3 {

}

h4, .h4{

}

h5, .h5{

}

p{
    a{
        font-weight: var(--font-weight-bold);
    }
}

small{
    font-weight: 300;
}
