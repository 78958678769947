*{
  box-sizing: border-box;
}

html{
  /* set base font-size to equiv "10px", which is adapted to rem unit */
  font-size: 62.5%;
  /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  font-size: calc(1em * 0.625);
}

.lpg{
  font-family: var(--font-helvetica-neue);
  font-size: var(--font-size-2);
  padding-top: 122px;
  font-weight: 400;

  main.main{
    background-image: url('../img/general/city-bg.svg');
    background-position: bottom center;
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .espaceur{
    margin: 150px 0 0;
  }
}
