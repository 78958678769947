.lpg .squelette-suivi{
    width: calc(100% - 20px);
    margin: 0 auto;
    background: linear-gradient(to right, rgba(234,154,63,1) 0%, rgba(250,195,67,1) 100%);
    border-radius:15px;
    box-shadow: 5px 5px 10px 0px rgba(12,15,46,.25);

    &__item{
      position: relative;
      width: 100%;
      padding: 35px 25px;
      border-bottom: 0.5px dashed #FFF;
      opacity: 0.4;
      transition: 1s;

      display: flex;

      &__illu{
        display: block;
        width: 45px;
        height: 45px;
        margin-right: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 40px;
        &.lieu{
          background-image: url('../img/bloc-suivi/maison.svg');
          background-size: 38px;
        }
        &.organisation{
          background-image: url('../img/bloc-suivi/checklist.svg');
          background-size: 31px;
        }
        &.rapport{
          background-image: url('../img/bloc-suivi/handshake.svg');
          background-size: 46px;
        }
        &.interets{
          background-image: url('../img/bloc-suivi/interets.svg');
          background-size: 42px;
        }
        &.coordonnees{
          background-image: url('../img/bloc-suivi/telephone.svg');
          background-size: 32px;
        }
        &.disponibilites{
          background-image: url('../img/bloc-suivi/calendrier.svg');
          background-size: 34px;
        }
      }

      &__content{
        width:calc(100% - 65px);
        h2{
          display: block;
          font-weight: 400;
          margin: 0;
          font-size: var(--font-size-1);
          font-family: var(--font-basic);
          color: #FFF;
        }
        p{
          display: block;
          max-width: 220px;
          margin: 12px 0 0;
          font-size: var(--font-size-4);
          color: #FFF;
        }
      }

      &__editer{
        display: none;
        position: absolute;
        bottom:10px;
        right:10px;
        width: 34px;
        height:34px;
        background-color: rgba(237,238,252,.4);
        border-radius: 50%;
        background-image: url('../img/icon/editer.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 12px;
        transition: .4s;
        &:hover{
          background-color: rgba(237,238,252,.6);
        }
      }
    }

    &__item.is-done .squelette-suivi__item__editer{
      display: block;
    }

    &__item:last-child{
      border-bottom: 0;
    }

    &__item.is-active{
      width: calc(100% + 20px);
      opacity: 1;
      border-bottom: 0;
      box-shadow: 0px 3px 6px 0px rgba(0,0,0,.16);
      border-radius: 15px;
      background-color: var(--color-jaune);
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -1px;
      &::after{
        content:'';
        position: absolute;
        top:50%;
        left:0;
        transform: translate(-50%,-50%);
        width: 19px;
        height:19px;
        border: 4px solid #FFF;
        border-radius: 50%;
        background-color: var(--color-jaune);
      }
    }

    &__item.is-done{
      border-bottom: 0.5px solid rgba(255,243,214,.6);
      opacity: 1;
      &::after{
        content:'';
        position: absolute;
        top:50%;
        left:0;
        transform: translate(-50%,-50%);
        width: 28px;
        height:28px;
        border: 4px solid #FFF;
        border-radius: 50%;
        background-color: var(--color-jaune);
        background-image: url('../img/icon/suivi-check.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 11px;
      }
    }

}
