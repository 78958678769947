.lpg .footer{
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-bleu);
  padding: 65px 20px 45px;

  &__bloc-link-principal{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    a{
      position: relative;
      display: block;
      font-family: var(--font-basic);
      font-size: var(--font-size-3);
      color:#FFF;
      text-decoration: none;
      margin: 0 22px;
      &::after{
        content:'';
        background-color: #FFF;
        position: absolute;
        left: 0;
        bottom:-5px;
        width: 100%;
        height:3px;
        opacity: 0;
        transition: .4s;
      }
      &:hover::after{
        opacity: 1;
      }
    }
  }

  &__bloc-social{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 45px 0 60px;
    a{
      display: block;
      width: 25px;
      height:25px;
      background-repeat: no-repeat;
      background-position: bottom center;
      margin: 0 15px;
      &.fb{
        background-image: url('../img/social/fb.svg');
        background-size: 12px;
      }
      &.twitter{
        background-image: url('../img/social/twitter.svg');
        background-size: 25px;
      }
      &.linkedin{
        background-image: url('../img/social/linkedin.svg');
        background-size: 22px;
      }
    }
  }

  &__bloc-link-secondaire{
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    a{
      display: block;
      font-size: var(--font-size-4);
      color: var(--color-gris);
      text-decoration: none;
      margin: 0 22px;
    }
  }

}

@media screen and (max-width: 699px) {

  .lpg .footer{
    padding: 25px 20px 25px;
  }

  .lpg .footer__bloc-social{
    margin: 30px 0 35px;
  }

  .lpg .footer__bloc-link-principal a{
    margin: 0 15px;
  }

  .lpg .footer__bloc-link-secondaire{
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    li{
      margin: 5px 0;
    }
  }
}
