.lpg .arianne{
  display: flex;
  flex-wrap: wrap;
  margin-right: 50px;

  &__item{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-basic);
    width: 70px;
    color:var(--color-gris);
    &.is-active{
      color:var(--color-jaune);

      .arianne__item__bulle{
        width: 32px;
        height:32px;
        background-color: var(--color-jaune);
        color:#FFF;
      }

      .ligne{
        border-top: 1px solid var(--color-jaune);
      }

      .arianne__item__libelle{
        display: block;
      }

    }

    &.is-done{
      color:var(--color-jaune);

      .arianne__item__bulle{
        background-color: var(--color-jaune);
        color:#FFF;
      }

      .ligne{
        border-top: 1px solid var(--color-jaune);
      }

    }

    &__bulle{
      position: relative;
      display: block;
      font-family: 'Arial';
      font-weight: 600;
      width: 20px;
      height:20px;
      line-height: 40px;
      text-align: center;
      border-radius: 50%;
      background-color: #FFF3D6;
      font-size: var(--font-size-3);
      color:var(--color-gris);
      z-index: 1;
    }

    &__libelle{
      position: absolute;
      width: 300px;
      display: none;
      top:100%;
      left:50%;
      transform: translateX(-50%);
      margin-top: 8px;
      text-align: center;
      font-size: 1.3rem;
      font-family: var(--font-basic);
    }

    .ligne{
      position: absolute;
      top:16px;
      width: calc(100% / 2);
      height:1px;
      border-top: 1px dotted var(--color-gris);
      &--gauche{
        left:0;
      }
      &--droite{
        left:calc(50% + 1px);
      }
    }
  }

  &__item:first-child .ligne--gauche{
    display: none;
  }

  &__item:last-child .ligne--droite{
    width: 100%;
    &::after{
      content:'';
      position: absolute;
      left: calc(100% - 13px);
      top:50%;
      transform: translateY(-50%);
      width: 30px;
      height:30px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url('../img/icon/ariane-arrow.svg');
      background-size: 30px;
    }
  }

  &__item.is-active:last-child .ligne--droite::after{
    background-image: url('../img/icon/ariane-arrow-active.svg');
  }

}

@media screen and (max-width: 991px) {
  .lpg .arianne{
    display: none;
  }
}
