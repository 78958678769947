.lpg{

  .container-general{
    display: flex;
  	flex-wrap: wrap;
  	padding:45px 25px 80px 90px;
  }

  .bloc-content{
    position: relative;
    /*width: calc(100% - 420px);*/
    padding: 100px 90px 0 10px;
  }

  @media screen and (max-width: 1199px) {

    .container-general{
      padding:45px 25px 80px 40px;
    }
    .bloc-content{
      padding: 100px 40px 0 10px;
    }
  }

  .bloc-suivi{
    position: relative;
    max-width: 500px;
  }

  @media screen and (max-width: 991px) {
    .container-general{
      padding:0px 20px 80px 20px;
    }
    .bloc-content{
      padding: 50px 10px 0 10px;
    }
    .bloc-suivi{
      display: none;
    }
  }

}
