/* Variables du projet ici */

:root{

	/** COLORS **/
		--color-jaune   : #FCC952;
   	--color-bleu   : #181D46;
		--color-beige : #FDE7E0;
		--color-rouge : #FD5864;
		--color-gris : #B7B7B7;

		/* FONT SIZE */
		--font-size-1: 1.9rem;
		--font-size-2: 1.6rem;
		--font-size-3: 1.4rem;
		--font-size-4: 1.2rem;
		--font-size-5: 1rem;

		/* FONT FAMILY */

		--font-basic: 'basic','helveticaneue','Arial', sans-serif;
		/* Pas d'helvetica pour l'instant */
		/* --font-helvetica: 'helvetica','helveticaneue','Arial', sans-serif; */
		--font-helvetica-neue: 'helveticaneue', 'Arial', sans-serif;

   	/** Nav Button **/
   	--burger-size      : 25px;
   	--burger-padding   : 1px;
   	--burger-background: transparent;
   	--burger-hover-background: transparent;
   	--burger-color     : red;
   	--burger-weight    : 3px;

    /* --site-width     : 1200; */
    /*--col-width      : 55;
    --col-width-px   : 55px;
    --gutter         : 30px;
	*/
	/* Unitless container width */
	/*--max-container-width--unitless: 990;*/


}


@custom-media --break-max-container-width ( max-width: 76rem );
@custom-media --break-min-container-width ( min-width: 76rem );
@custom-media --break-mobilemenu ( max-width: 36rem );
@custom-media --break-min-mobilemenu ( min-width: 36rem );


@custom-media --break-min-sm ( min-width: 22rem );
@custom-media --break-min-md ( min-width: 45rem );
@custom-media --break-min-lg ( min-width: 60rem );

@custom-media --break-max-sm ( max-width: 22rem );
@custom-media --break-max-md ( max-width: 45rem );
@custom-media --break-max-lg ( max-width: 60rem );
