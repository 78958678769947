.lpg .header{
  width: 100%;
  position: fixed;
  top:0;
  left: 0;
  z-index: 1000;
  &__bloc{
    width: 100%;
    padding:30px 40px;
    background-color: #FFF;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,.16);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .right-bloc{
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    &__logo{
      display: block;
      img{
        display: block;
        width: 97px;
        height:45px;
      }

      @media screen and (max-width: 991px) {
        &--desktop{
          display: none;
        }
      }
      @media screen and (min-width: 992px) {
        &--mobile{
          display: none;
        }
      }

    }

    &__assistance{
      margin-right: 60px;
      text-align: right;
      text-decoration: none;
      span{
        font-family: var(--font-basic);
        display: block;
        font-size: 1.6rem;
        color:var(--color-bleu);
        &.small{
          font-size: 1.2rem;
        }
      }
    }

    @media screen and (max-width: 991px) {
      .header__bloc__assistance{
        display: none;
      }
    }

    &__etapes{
      display: flex;
      flex-direction: column;
      text-align: center;
      &__nbEtapes{
        display: block;
        font-family: var(--font-basic);
        font-size: var(--font-size-3);
        color:var(--color-bleu);
        margin-bottom: 2px;
      }
      &__libelle{
        display: block;
        font-family: var(--font-basic);
        font-size: var(--font-size-3);
        color:var(--color-jaune);
        margin-top: 2px;
      }
    }

    @media screen and (min-width: 992px) {
      .header__bloc__etapes{
        display: none;
      }
    }

    &__user{
      display: block;
      width: 35px;
      height:35px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 22px;
      background-image: url('../img/icon/user_header.svg');
    }

  }
}
