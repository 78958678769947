.lpg .bulle{
   display: flex;
   align-items: flex-end;

   &__photo{
     display: block;
     width: 100px;
     height:100px;
     background-position: center;
     background-repeat: no-repeat;
     background-size: cover;
     border-radius: 50%;
     margin-right: 20px;
   }

   &__content{
     width: calc(100% - 120px);
     background-color: var(--color-beige);
     border-top-left-radius: 15px;
     border-top-right-radius: 15px;
     border-bottom-right-radius: 15px;
     padding: 0 30px;
     p{
       color:var(--color-bleu);
       margin: 20px 0;
       line-height: 1.5;
       span{
         font-weight: 700;
       }
     }
   }
}

@media screen and (max-width: 991px) {
  .lpg .bulle{
    &__photo{
      width: 60px;
      height:60px;
    }

    &__content{
      width: calc(100% - 80px);
    }
  }
}
