.lpg .formulaire{
  margin: 25px 0;
  padding: 0 0 0 120px;

  &__line{
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 15px;

    input[type="text"]{
      margin: 0;
      width: 100%;
      background-color: #FFF;
      color:var(--color-blue);
      border:0.5px solid var(--color-gris);
      padding: 15px 20px 15px 20px;
      outline: 0;

      &.is-active{
        border:0.5px solid var(--color-jaune);
      }

      &::placeholder{
        color: var(--color-gris);
      }

      &.w50{
        width: calc(50% - 5px);
      }
    }

    select{
      margin: 0;
      width: 100%;
      background-color: #FFF;
      color:var(--color-blue);
      border:0.5px solid var(--color-gris);
      padding: 15px 20px 15px 45px;
      outline: 0;
      border-radius: 50px;
      appearance: none;

      &.is-active{
        border:0.5px solid var(--color-jaune);
      }
    }

    .arrow{
      position: absolute;
      right:25px;
      top:50%;
      transform: translateY(-50%);
      width: 20px;
      height:20px;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 14px;
      background-image: url('../img/icon/arrow-down.svg');
      pointer-events: none;

      &.is-active{
        background-image: url('../img/icon/arrow-down-yellow.svg');
      }
    }

  }

  .bloc-cta{
		margin-top: 25px;
		text-align: right;
	}
}

@media screen and (max-width: 991px) {
  .lpg .formulaire{
    padding: 0 0 0 20px;
  }
}

@media screen and (max-width: 699px) {
  .lpg .formulaire{
    &__line{
      input[type="text"]{
        &.w50{
          &:first-child{
            margin-bottom: 15px;
          }
          width: 100%;
        }
      }
    }
    .bloc-cta{
  		text-align: center;
  	}
  }
}
