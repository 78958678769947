@font-face {
	font-family: 'basic';
	src: url('../fonts/basic-regular-webfont.woff2') format('woff2'),
		url('../fonts/basic-regular-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
/*
@font-face {
    font-family: 'helvetica';
    src: url('../fonts/helvetica-webfont.woff2') format('woff2'),
         url('../fonts/helvetica-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
*/

@font-face {
    font-family: 'helveticaneue';
    src: url('../fonts/subset-HelveticaNeue.woff2') format('woff2'),
         url('../fonts/subset-HelveticaNeue.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'helveticaneue';
    src: url('../fonts/subset-HelveticaNeue-Medium.woff2') format('woff2'),
         url('../fonts/subset-HelveticaNeue-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'helveticaneue';
    src: url('../fonts/subset-HelveticaNeue-Bold.woff2') format('woff2'),
         url('../fonts/subset-HelveticaNeue-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
