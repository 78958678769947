.lpg{

	.cta-classique{
		background-color: #FFF;
		display: inline-block;
		min-width: 245px;
		padding: 15px 20px;
		border-radius: 25px;
		border: 2px solid transparent;
		text-align: center;
		text-decoration: none;
		cursor: pointer;
		transition: .3s;

		&--jaune{
			border: 2px solid var(--color-jaune);
			color:var(--color-jaune);
			&:hover{
				background-color: var(--color-jaune);
				color: #FFF;
			}
		}
		&--gris{
			border: 2px solid var(--color-gris);
			color:var(--color-gris);
			&:hover{
				background-color: var(--color-gris);
				color: #FFF;
			}
		}
		&--rouge{
			border: 2px solid var(--color-rouge);
			color:var(--color-rouge);
		}
		&--margeGauche{
			margin-left: 50px;
		}

		&.is-disabled{
			border: 2px solid var(--color-gris);
			color:var(--color-gris)!important;;
			background-color: #FFF!important;
			cursor: not-allowed;
		}
	}

	.bloc-cta-general{
		margin-top: 25px;
		text-align: right;
	}

	@media screen and (max-width: 699px) {
		.bloc-cta-general{
			text-align: center;
			.cta-classique{
				margin-bottom: 15px;
			}
		}
	}

	@media screen and (max-width: 1199px) {
		.cta-classique--margeGauche{
			margin-left: 5px;
		}
	}


}
