.lpg .radio-questionnnaire{
  margin: 25px 0;
  padding: 0 0 0 120px;

  form{
    input{
      display:none;
    }
  }

  &__item{
    position: relative;
    display: block;
    width: 100%;
    border:0.5px solid var(--color-gris);
    padding: 17px 10px 17px 70px;
    margin-bottom: 10px;
    transition: .6s;
    cursor: pointer;

    &__check{
      position: absolute;
      left:0;
      top:0;
      width: 40px;
      height:100%;
      border-right: 0.5px solid var(--color-gris);
    }

    &__text{
      color:var(--color-bleu);
    }

    &.is-active{
      border:0.5px solid var(--color-jaune);
      .radio-questionnnaire__item__text{
        color: var(--color-jaune);
      }
      .radio-questionnnaire__item__check{
        background-color: var(--color-jaune);
        border-right: 0.5px solid var(--color-jaune);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 17px;
        background-image: url('../img/icon/radio-check.svg');
      }
    }
  }

  .bloc-cta{
		margin-top: 25px;
		text-align: right;
	}

}

@media screen and (max-width: 991px) {
  .lpg .radio-questionnnaire{
    padding: 0 0 0 20px;
    .bloc-cta{
  		text-align: center;
  	}
  }

}
